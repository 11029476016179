import Cookies from "js-cookie";

const saveSession = (user, userToken) => {
  Cookies.set("serverless", { user, userToken });
};

const getSession = () => {
  const data = Cookies.get("serverless");
  return data ? JSON.parse(data) : null;
};

const deleteSession = () => {
  Cookies.remove("serverless");
};

export { saveSession, getSession, deleteSession };
