import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import TopBar from "../../components/TopBar";
import SidebarMenu from "../../components/SidebarMenu";

import useStyles from "./styles";

const MainLayout = ({ children }) => {
  const classes = useStyles();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = (isOpen) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setIsMenuOpen(isOpen);
  };

  useEffect(() => {
    document.title = children.props.title || "";
  }, [children.props.title]);

  return (
    <Box role="main">
      <TopBar toggleMenu={toggleMenu} title={children.props.title} />
      <SidebarMenu isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} setIsMenuOpen={setIsMenuOpen} />
      <Box className={classes.content}>{children}</Box>
    </Box>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MainLayout;
