import React, { useEffect } from "react";
import PropTypes from "prop-types";
import useStyles from "./styles";

const LoginLayout = ({ children }) => {
  const classes = useStyles();
  useEffect(() => {
    document.title = children.props.title || "";
  }, [children.props.title]);

  return (
    <main className={classes.layout} role="main">
      <div className="container">{children}</div>
    </main>
  );
};

LoginLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LoginLayout;
