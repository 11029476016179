import React from "react";
import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";

import PrivateRoute from "./router/PrivateRoute";
import PublicRoute from "./router/PublicRoute";

import MainLayout from "./layouts/MainLayout";
import LoginLayout from "./layouts/LoginLayout";

import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import Transactions from "./components/Transactions";

const App = () => (
  <Router>
    <Switch>
      <PublicRoute exact path="/login" component={Login} title="Login" layout={LoginLayout} />
      <PrivateRoute exact path="/" component={Dashboard} title="Users" layout={MainLayout} />
      <PrivateRoute exact path="/transactions" component={Transactions} title="Transactions" layout={MainLayout} />
      <Redirect to="/" />
    </Switch>
  </Router>
);

export default App;
