import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import Drawer from "@material-ui/core/Drawer";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";

import useStyles from "./styles";

const menu = [
  { id: "users", name: "Users", path: "/" },
  { id: "transactions", name: "Transactions", path: "/transactions" },
];

const SidebarMenu = ({ isMenuOpen, toggleMenu, setIsMenuOpen }) => {
  const history = useHistory();
  const [activeMenuItem, setActiveMenuItem] = useState(
    menu.find(({ path }) => path === history.location.pathname)?.id,
  );

  const classes = useStyles();

  const handleMenuItemClick = (id, path) => {
    setActiveMenuItem(id);
    setIsMenuOpen(false);
    history.push(path);
  };

  return (
    <Drawer anchor="left" open={isMenuOpen} onClose={toggleMenu(false)}>
      <MenuList className={classes.container}>
        {menu.map(({ id, name, path }) => (
          <MenuItem
            key={id}
            classes={{ selected: classes.selectedMenuItem }}
            selected={id === activeMenuItem}
            onClick={() => handleMenuItemClick(id, path)}
          >
            {name}
          </MenuItem>
        ))}
      </MenuList>
    </Drawer>
  );
};

SidebarMenu.propTypes = {
  isMenuOpen: PropTypes.bool.isRequired,
  toggleMenu: PropTypes.func.isRequired,
  setIsMenuOpen: PropTypes.func.isRequired,
};

export default SidebarMenu;
