import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Menu from "@material-ui/icons/Menu";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import AccountCircle from "@material-ui/icons/AccountCircle";
import IconButton from "@material-ui/core/IconButton";
import Popover from "@material-ui/core/Popover";

import { getSession, deleteSession } from "../../utils/session";
import useStyles from "./styles";

const TopBar = ({ toggleMenu, title }) => {
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpenPopoverClick = (event) => setAnchorEl(event.currentTarget);

  const handleClosePopoverClick = () => setAnchorEl(null);

  const handleLogoutClick = () => {
    deleteSession();
    history.push("/login");
  };

  const open = Boolean(anchorEl);
  const { user: { firstName, lastName } } = getSession();

  return (
    <AppBar position="relative">
      <Toolbar className={classes.toolbar}>
        <Button onClick={toggleMenu(true)}>
          <Menu className={classes.icon} />
        </Button>
        <Typography variant="h6" color="inherit" className={classes.title}>{title}</Typography>
        <IconButton
          edge="end"
          aria-label="account of current user"
          aria-haspopup="true"
          onClick={handleOpenPopoverClick}
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <Popover
          id="user-popover"
          open={open}
          anchorEl={anchorEl}
          onClose={handleClosePopoverClick}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Typography className={classes.userPopover}>{`${firstName} ${lastName}`}</Typography>
        </Popover>
        <Button color="inherit" className={classes.logout} onClick={handleLogoutClick}>Logout</Button>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  toggleMenu: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default TopBar;
