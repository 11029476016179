import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";

import { getSession } from "../../utils/session";

const PrivateRoute = ({
  component: Component, layout: Layout, title, path, ...rest
}) => {
  const session = getSession();

  return (
    <Route
      path={path}
      {...rest}
      render={(props) => (
        !session
          ? <Redirect to="/login" />
          : (
            <Layout>
              <Component title={title} {...props} />
            </Layout>
          )
      )}
    />
  );
};

PrivateRoute.propTypes = {
  path: PropTypes.string.isRequired,
  exact: PropTypes.bool.isRequired,
  component: PropTypes.func.isRequired,
  layout: PropTypes.func.isRequired,
  title: PropTypes.string,
};

PrivateRoute.defaultProps = {
  title: "",
};

export default PrivateRoute;
