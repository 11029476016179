import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { DataGrid, GridToolbar } from "@material-ui/data-grid";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import UserPopup from "./userPopup";
import ConfirmationPopup from "./confirmationPopup";
import { userGetList, userDelete } from "../../api/user.api";
import { getSession, deleteSession } from "../../utils/session";
import useStyles from "./styles";

const columns = [
  { field: "firstName", headerName: "First name", width: 200 },
  { field: "lastName", headerName: "Last name", width: 200 },
  {
    field: "email",
    headerName: "Email",
    flex: 1,
    minWidth: 250,
  },
  {
    field: "type",
    headerName: "Type",
    width: 110,
  },
  {
    field: "edit",
    headerName: "Edit",
    sortable: false,
    width: 50,
    disableClickEventBubbling: true,
    isEditable: false,
    disableExport: true,
    filterable: false,
    renderCell: () => <IconButton size="small"><EditIcon className="edit" color="primary" /></IconButton>,
    renderHeader: () => <div />,
  },
  {
    field: "delete",
    headerName: "Delete",
    sortable: false,
    width: 50,
    disableClickEventBubbling: true,
    isEditable: false,
    disableExport: true,
    filterable: false,
    renderCell: () => <IconButton size="small"><DeleteIcon className="delete" color="primary" /></IconButton>,
    renderHeader: () => <div />,
  },
];

const Dashboard = () => {
  const history = useHistory();
  const classes = useStyles();
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [editingUser, setEditingUser] = useState({});
  const [deleteUserEmail, setDeleteUserEmail] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const data = await userGetList();
      setUsers(data);
      setIsLoading(false);
    }
    fetchData();
  }, []);

  const currentlySelected = (params) => {
    const { row, field } = params;
    if (field === "delete") {
      setDeleteUserEmail(row.email);
      setIsConfirmationOpen(true);
      return;
    }
    if (field === "edit") {
      setEditingUser(row);
      setIsPopupOpen(true);
    }
  };

  const handleTogglePopup = useCallback(
    () => setIsPopupOpen(!isPopupOpen),
    [setIsPopupOpen, isPopupOpen],
  );

  const handleAddNewUser = useCallback(
    () => {
      setEditingUser({});
      handleTogglePopup();
    },
    [setEditingUser, handleTogglePopup],
  );

  const updateUsers = useCallback(
    (user) => setUsers([...users, user]),
    [setUsers, users],
  );

  const handleConfirmationPopup = useCallback(
    () => setIsConfirmationOpen(!isConfirmationOpen),
    [setIsConfirmationOpen, isConfirmationOpen],
  );

  const handleRemoveUser = useCallback(
    async () => {
      try {
        if (!deleteUserEmail) return;
        await userDelete(deleteUserEmail);
        const { user } = getSession();
        if (user.email === deleteUserEmail) {
          deleteSession();
          history.push("/login");
        }
        setUsers(users.filter(({ email }) => email !== deleteUserEmail));
        setIsConfirmationOpen(false);
      } catch (e) {
        console.log(e);
      }
    },
    [deleteUserEmail, users, history],
  );

  useEffect(() => {
    if (!isConfirmationOpen) setDeleteUserEmail(null);
  }, [isConfirmationOpen]);

  return (
    <div style={{ height: 700, maxWidth: 880, margin: "0 auto" }}>
      <Button size="small" variant="contained" color="primary" startIcon={<AddIcon />} style={{ marginBottom: "10px" }} onClick={handleAddNewUser}>
        Create
      </Button>
      <DataGrid
        className={classes.root}
        rows={users}
        columns={columns}
        pageSize={10}
        autoHeight
        disableColumnMenu
        onCellClick={currentlySelected}
        isRowSelectable
        components={{
          Toolbar: GridToolbar,
        }}
        loading={isLoading}
      />
      <UserPopup
        isPopupOpen={isPopupOpen}
        handleTogglePopup={handleTogglePopup}
        editingUser={editingUser}
        updateUsers={updateUsers}
      />
      <ConfirmationPopup
        isPopupOpen={isConfirmationOpen}
        handleTogglePopup={handleConfirmationPopup}
        handleActionClick={handleRemoveUser}
      />
    </div>
  );
};

export default Dashboard;
