import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
      outline: "none",
    },
  },
  popup: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 4),
    borderRadius: "5px",
    minWidth: "400px",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  formField: {
    margin: "8px 0",
  },
  optionField: {
    margin: "5px",
    padding: "5px 3px",
    borderBottom: "1px solid #999",
    cursor: "pointer",
    "&:last-child": {
      border: "none",
    },
  },
}));

export default useStyles;
