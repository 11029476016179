import { userLogin, userGet } from "../../api/user.api";
import { saveSession } from "../../utils/session";

const submitForm = async (login, password) => {
  try {
    const { token } = await userLogin(login, password);
    const { user } = await userGet(token);
    saveSession({ email: user.email, firstName: user.firstName, lastName: user.lastName }, token);
    return { status: true };
  } catch (e) {
    return { status: false, message: e.message };
  }
};

export { submitForm };
