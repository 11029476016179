import { requestApi } from "../utils/request";
import { getSession } from "../utils/session";

const txGetListByUserEmail = async (userEmail) => {
  const { userToken } = getSession();
  return requestApi("/transactions/byUserEmail", "POST", { userEmail }, {
    Authorization: `Bearer ${userToken}`,
  });
};

const txExportByUserEmail = async (userEmail, from, to) => {
  const { userToken } = getSession();
  return requestApi("/transactions/byUserEmail/export", "POST", { userEmail, from, to }, {
    Authorization: `Bearer ${userToken}`,
  });
};

const txDeleteByUserEmail = async (userEmail) => {
  const { userToken } = getSession();
  return requestApi("/transactions/byUserEmail", "DELETE", { userEmail }, {
    Authorization: `Bearer ${userToken}`,
  });
};

export {
  txGetListByUserEmail, txDeleteByUserEmail, txExportByUserEmail,
};
